
  import { IonPage, IonIcon, IonButton, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/vue';
  import { IonGrid, IonRow, IonCol } from '@ionic/vue';
  import libStore from "@/lib/libStore";
  import {defineComponent} from "vue";
  import {chevronBackOutline} from "ionicons/icons";
  import LogoContainer from '@/components/LogoContainer.vue';

  export default defineComponent({
    name: "NotFound",
    components: {
      IonButton,
      IonHeader,
      IonToolbar,
      IonTitle,
      IonGrid,
      IonRow,
      IonCol,
      IonContent,
      IonIcon,
      IonPage,
      LogoContainer
    },
    setup () {
      const { goToMainPage } = libStore();
      return {
        chevronBackOutline,
        goToMainPage
      }
    },
  });
